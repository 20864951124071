import React, {useState, useEffect, useRef} from 'react'
import './supported_games.css'

function SupportedGames(props) {
    const section_ref = useRef(null);
    useEffect(() => {
        if(section_ref !== null){
            props.setGamesSection(section_ref);
        }
    }, [section_ref]);
    return (
        <section className='games-section' ref={section_ref}>
            <div className='games-container'>
                <div className='games-container-text'>
                    <p style={{opacity: 1, color: '#ffb41f'}}>Cheat in your favorite game</p>
                    <p style={{fontSize: '32px', fontWeight: 500}}>Games we provide for</p>
                </div>
                <div className='games-list-container'>
                <div >
                    <div className="card">
                        <div className="wrapper">
                        <img src="./res/img/fortnite card.jpg" className="cover-image" alt='fortniteCard'/>
                        </div>
                        <img src="./res/img/fortnite logo.png" className="title" alt='forniteLogo'/>
                        <img src="./res/img/skulltrooper.png" className="character" alt='skullTrooper'/>
                    </div>
                </div>
                <div >
                    <div className="card">
                        <div className="wrapper">
                        <img src="./res/img/cod warzone card.jpg" className="cover-image" alt='codCard'/>
                        </div>
                        <img src="./res/img/cod logo.png" className="title" alt='codLogo'/>
                        <img src="./res/img/ghost.png" className="character" alt='Ghost'/>
                    </div>
                </div>
                <div >
                    <div className="card">
                        <div className="wrapper">
                        <img src="./res/img/pubg card.jpg" className="cover-image" alt='pubgCard'/>
                        </div>
                        <img src="./res/img/pubg mobile logo.png" className="title" alt='pubgLogo'/>
                        <img src="./res/img/pubg player.png" className="character" alt='pubgPlayer'/>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default SupportedGames