import React, {useState, useEffect, useRef} from 'react'
import './steps.css'
import gsap from 'gsap';
import ScrollTrigger from 'gsap-trial/ScrollTrigger';
import { useGSAP } from '@gsap/react'
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function Steps(props) {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const steps_list = useRef();
    const steps_container = useRef();
    const text_div = useRef();

    useGSAP(
        () => {
            gsap.utils.toArray('.step-box').forEach((box, i) => {
                gsap.to(box, {
                    background: '#ffb41f',
                    color: 'black',
                    fontWeight: '600',
                    scale: 1,
                    scrollTrigger: {
                        trigger: box,
                        start: "top center+=100",
                        end: "bottom center",
                        toggleActions: "play none none reverse",
                    }
                });
            });
        },
        { scope: steps_list }
    );

    useGSAP(
        () => {
            gsap.to(text_div.current, {
                scrollTrigger: {
                    trigger: '.steps-container-text',
                    start: "top center",
                    end: 'top+=400 top',
                    pin: true,
                    scrub: 1,
                }
            });
        },
        { scope: steps_container }
    );


    useEffect(() =>{
        if(steps_container !== null){
            props.setStepsSection(steps_container);
        }
    }, [steps_container]);

    return (
        <section className='steps-section' ref={steps_container}>
            <div className='steps-container'>
                <div className='steps-container-text' ref={text_div}>
                    <p style={{opacity: 1, color: '#ffb41f'}}>I'm ready to dominate</p>
                    <p style={{fontSize: '32px', fontWeight: 500}}>How to get access</p>
                </div>
                <div className='steps-container-text-mobile'>
                    <p style={{opacity: 1, color: '#ffb41f'}}>I'm ready to dominate</p>
                    <p style={{fontSize: '32px', fontWeight: 500}}>How to get access</p>
                </div>
                <div className='steps-list' ref={steps_list}>
                    <div className='step-box'>
                        <div className='step-box-inner'>
                            <p>1.</p>
                            <p>Contact one of the admins and send him the invitation code you received, along with a message saying that you want to join the community. You don't have an invitation or don't know any admin to contact? Too bad, find a member to invite you and tell you who to message. We are a private community, and without an invitation, you cannot join.</p>
                        </div>
                    </div>
                    <div className='steps-connection'>
                        <svg width="15" height="200">
                            <rect x={0} y={0} width={15} height={15} fill='#ffb41f'/>
                            <line x1="7.5" y1="20" x2="7.5" y2="170" stroke="#ffb41f" strokeWidth="2" />
                            <rect x={0} y={175} width={15} height={15} fill='#ffb41f'/>
                        </svg>
                    </div>
                    <div className='step-box'>
                        <div className='step-box-inner'>
                            <p>2.</p>
                            <p>You will receive a crypto wallet address to pay the monthly subscription fee of $25. We only accept crypto.</p>
                        </div>
                    </div>
                    <div className='steps-connection'>
                        <svg width="15" height="200">
                            <rect x={0} y={0} width={15} height={15} fill='#ffb41f'/>
                            <line x1="7.5" y1="20" x2="7.5" y2="170" stroke="#ffb41f" strokeWidth="2" />
                            <rect x={0} y={175} width={15} height={15} fill='#ffb41f'/>
                        </svg>
                    </div>
                    <div className='step-box'>
                        <div className='step-box-inner'>
                            <p>3.</p>
                            <p>Congratulations, you are in! You have been invited to our private server and have full access to all of our cheats. Explore each cheat, learn to use it using our well-documented tutorials, chat with other members, and message our customer support which is active 24/7 if you need any help.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Steps