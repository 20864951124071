import React, {useState, useEffect} from 'react'
import './hero.css'

function Hero(props) {

    const btnClicked = () =>{
        props.scrollToGames();
    }

    return (
        <section className='hero-section'>
            <div className='hero-left-side'>
            </div>
            <div className='hero-right-side'>
            </div>
            <div className='hero-content-container'>
                <img src='./res/img/imperial stormtrooper.png' alt='Trooper' id='trooper'/>
                <p className='hero-title' data-hero-title='MOGOSU CHEATS'>MOGOSU CHEATS</p>
                <div className='hero-content-details'>
                    <p>Anyone can be a pro player, <span style={{color: '#FFB41F'}}>even you</span>, with our help!</p>
                    <div className='hero-content-btn' onClick={btnClicked}>
                        <p>See more</p>
                    </div>
                </div>
                <div className='hero-content-details-mobile'>
                    <div className='hero-content-details-mobile-inner'>
                        <p>Anyone can be a pro player, <span style={{color: 'black', fontWeight: '500', fontStyle: 'italic'}}>even you</span>, with our help!</p>
                        <div className='hero-content-btn' onClick={btnClicked}>
                            <p>See more</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero