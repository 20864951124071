import './App.css';
import Hero from './components/hero/hero';
import SupportedGames from './components/supported_games/supported_games';
import Features from './components/features/features';
import Steps from './components/steps/steps';
import Refund from './components/refund/refund';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import { useRef, useState } from 'react';

function App() {

  let games_section = useRef(null);
  const setGamesSection = (newRef) => {
    games_section = newRef;
  }

  let features_section = useRef(null);
  const setFeaturesSection = (newRef) => {
    features_section = newRef;
  }

  let steps_section = useRef(null);
  const setStepsSection = (newRef) => {
    steps_section = newRef;
  }

  const scrollToGames = () =>{
    games_section.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToFeatures = () =>{
    features_section.current.scrollIntoView({ behavior: 'smooth' });
  }


  const scrollToSteps = () =>{
    steps_section.current.scrollIntoView({ behavior: 'smooth' });
  }


  return (
    <div className="App">
      <Header scrollToGames={scrollToGames} scrollToFeatures={scrollToFeatures} scrollToSteps={scrollToSteps}/>        
      <Hero scrollToGames={scrollToGames} />
      <SupportedGames setGamesSection={setGamesSection} />
      <Features setFeaturesSection={setFeaturesSection}/>
      <Steps setStepsSection={setStepsSection}/>
      <Refund/>
      <Footer/>
    </div>
  );
}

export default App;
