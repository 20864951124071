import React from 'react'
import './footer.css'

function Footer() {
    return (
        <section className='footer-section'>
            <div className='footer-container'>
                <p>© 2024 MOGOSU Cheats | All rights reserved.</p>
            </div>
        </section>
    )
}

export default Footer