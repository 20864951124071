import React from 'react'
import './refund.css'

function Refund() {
    return (
        <section className='refund-section'>
            <div className='refund-box'>
                <p style={{fontSize: '20px', color: '#FFB41F'}}>Cancel and get your money back if you're not satisfied!</p>
                <p>At the start of each month of subscription, you have 7 days to request a refund no matter the reason. You will be 100% reimbursed the full amount of the subscription price in less than 48 hours. Refunding will not revoke your access to our community, but you will lose access to any of our services and products. Your account will remain valid and you can activate your subscription at any time to regain access.</p>
            </div>
        </section>
    )
}

export default Refund