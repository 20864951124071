import React, {useState, useEffect} from 'react'
import './header.css'

function Header(props) {

    const scrollToOnClick = (section) =>{
        if(section === 'games'){
            props.scrollToGames();
        }else if(section === 'features'){
            props.scrollToFeatures();
        }else if(section === 'steps'){
            props.scrollToSteps();
        }
    }

    return (
        <header className='header-section'>
            <div className='header-container'>
                <p className='header-logo'></p>
                <div className='header-nav'>
                    <div className='header-nav-content'>
                        <p onClick={() => scrollToOnClick('games')}>Games</p>
                        <p onClick={() => scrollToOnClick('features')}>Features</p>
                        <p onClick={() => scrollToOnClick('steps')}>Get access</p>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header