import React, {useState, useEffect, useRef} from 'react'
import './features.css'
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player/lazy';

function Features(props) {

    const features_ref = useRef(null);

    useEffect(() =>{
        if(features_ref !== null){
            props.setFeaturesSection(features_ref);
        }
    }, [features_ref]);

    const [height, setHeight] = useState(0);
    const videoRef = useRef(null);

    useEffect(() => {
        const updateHeight = () => {
        if (videoRef.current) {
            const width = videoRef.current.offsetWidth;
            setHeight(width * (9 / 16));
        }
        };

        // Set the initial height
        updateHeight();

        // Update the height on window resize
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    return (
        <section className='features-section' ref={features_ref}>
            <div className='features-container'>
                <div className='features-container-text'>
                    <p style={{opacity: 1, color: '#ffb41f'}}>Everything you need in a single software</p>
                    <p style={{fontSize: '32px', fontWeight: 500}}>Our cheats features</p>
                </div>
                <div className='features-grid-row'>
                    <div className='feature-grid-content'>
                        <img src='./res/img/aim.png' className='feature-img'/>
                        <p className='feature-title'>Aimbot</p>
                        <p className='feature-text'>Whether you're looking for an aimbot or just an aim-assist, we got you covered. We offer both external aimbots that you can run on your machine and hardware aimbots that you can use with your Arduino / Raspberry Pi device to be even more discrete.</p>
                    </div>
                    <div className='feature-grid-content'>
                        <img src='./res/img/wallhack.png' className='feature-img'/>
                        <p className='feature-title'>Wallhack & ESP</p>
                        <p className='feature-text'>How useful would it be for you to know every enemy position all the time? You'd be on a rampage! This is why our cheats have very customizable wallhack and ESP features. Change everything from the range and outline color to the items you see on the screen.</p>
                    </div>
                    <div className='feature-grid-content'>
                        <img src='./res/img/bypass.png' className='feature-img'/>
                        <p className='feature-title'>Undetectable</p>
                        <p className='feature-text'>Never detected and no user was ever banned. How do we do it? Most anti-cheats are kernel nowadays and the best way to avoid their detection is to also go kernel level. All our cheats are kernel-level, or even better, hardware cheats as we offer Arduino / Raspberry Pi aimbots with spoofing included.</p>
                    </div>
                </div>
                <div className='features-grid-row'>
                    <div className='feature-grid-content'>
                        <img src='./res/img/recoil control.png' className='feature-img'/>
                        <p className='feature-title'>Recoil control</p>
                        <p className='feature-text'>Never miss a shot again because of poor recoil control. Let us do the work for you and enjoy your game to its maximum potential. We update our cheats with every game update and optimize the recoil control feature to work well with any gun in the game.</p>
                    </div>
                    <div className='feature-grid-content'>
                        <img src='./res/img/private.png' className='feature-img'/>
                        <p className='feature-title'>Private cheat</p>
                        <p className='feature-text'>Do you have an invitation? Cause our doors are not open for everyone. We take pride in our work and consider that being a private community allows us to maintain high quality and no detection of our cheats.</p>
                    </div>
                    <div className='feature-grid-content'>
                        <img src='./res/img/more.png' className='feature-img'/>
                        <p className='feature-title'>Many more</p>
                        <p className='feature-text'>There are many more awesome features in our cheats. But since you are here, most likely you already heard about our cheats and know how good they are.</p>
                    </div>
                </div>
                <div className='features-video-section' style={{height: `${height}px`}} ref={videoRef}>
                    <ReactPlayer url='https://www.youtube.com/watch?v=jPWCU0oczw4&ab_channel=Renegade' controls={true} className='features-video' width={'100%'} height={'100%'} />
                </div>
            </div>
        </section>
    )
}

export default Features